import {
  ChangePharmaciesCityAction,
  ClearFoundActionSuccess,
  ClearListPharmaciesAction,
  ResetOtherPageCountLoadPharmaciesAction,
  ResetPageCountLoadPharmaciesAction,
  SetCenterMapAction,
  SetCurrenCityLabelSuccess,
  SetCurrentCityAction,
  SetCurrentCityId,
  SetCurrentCitySuccess,
  SetCurrentCoordSuccess,
  SetCurrentTownEnAction,
  SetCurrentUserTownWithCoords,
  SetPopupVisibleChangeLocationAction,
  SetTargetSuccess,
  setTotalPharmaciesLengthAction,
  SetVariantsLocationInPopupAction
} from '../../../store/actions';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { AppState } from '@appStates';
import { select, Store } from '@ngrx/store';
import { selectCurrentCityId } from '../../../store/selectors';
import { ScreenDetectedService } from '../screen-detected.service';
import { LocalizationService } from '../localization.service';
import { FilterListPharmcies, IBuilding, ICity, IProduct, RequestParamsListPharmcy } from '@interfaces';
import { currentMostOftenGroup } from 'src/app/store/selectors/start.page.selectors';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { CookiesServiceService } from '../storage.services/cookies-service.service';
import { LocationService } from './location.service';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { InfoDrugApiService } from '../api.services/info-drug-api.service';
import { SearchPharmaciesApiService } from '../api.services/search-pharmacies-api.service';
import { TopBookingApiService } from '../api.services/top-booking-api.service';
import { ViewedGoodsApiService } from '../api.services/viewed-goods-api.service';
import { LocationStorageService } from '../storage.services/location-storage-service.service';
import { InfoDrugHelperService } from '../helpers/info-drug-helper.service';
import { FavoriteGoodsApiService } from '../api.services/favorite-goods-api.service';
import { PharmaciesNavigationHelperService } from '../helpers/help-service-for-search-page/pharmacies-navigation-helper.service';
import { BehaviorSubject, from, Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  public hidePopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public route: ActivatedRouteSnapshot;

  public catalogCityChangeEmitter = new EventEmitter<string>();

  constructor(
    private readonly viewedGoodsApiService: ViewedGoodsApiService,
    private readonly topBookingApiService: TopBookingApiService,
    private readonly searchPharmaciesApiService: SearchPharmaciesApiService,
    private readonly infoDrugApiService: InfoDrugApiService,
    private readonly cook: CookiesServiceService,
    private readonly favoriteGoods: FavoriteGoodsApiService,
    private readonly local: LocalizationService,
    private readonly locationService: LocationService,
    private readonly screen: ScreenDetectedService,
    private readonly pharmaciesNavigationHelper: PharmaciesNavigationHelperService,
    private readonly infoDrugHelper: InfoDrugHelperService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly platformLocation: PlatformLocation,
    private readonly router: Router,
    private readonly locationStorageService: LocationStorageService,
    private readonly store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route = this.activatedRoute.snapshot;
  }

  public isAllowableGeoLocation(): Observable<boolean> {
      return new Observable((obs: Subscriber<boolean>): void => {
        navigator.geolocation.getCurrentPosition(
          () => {
            obs.next(true);
            obs.complete();
          },
          () => {
            obs.error(false);
          }
        );
      });
  }

  public getPermissionStatus(): Observable<PermissionStatus> {
    if (navigator.permissions && navigator.permissions.query) {
      return from(navigator.permissions.query({ name: 'geolocation' }));
    } else {
      return this.isAllowableGeoLocation().pipe(
        map((allowed: boolean) => ({
          state: allowed ? 'granted' : 'denied'
        } as PermissionStatus))
      );
    }
  }

  public setLocation(): void {
    this.detectDeviceLocation();
  }

  public detectDeviceLocation(): void {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const longitude: number = coords.longitude;
      const latitude: number = coords.latitude;
      this.getCityFromCoord(latitude, longitude);
    }, (error) => {
      // tslint:disable-next-line:no-console
      console.warn(`Get Current Position Error: ${error.message}`);
    }, { timeout: 10000 });
  }

  public getCityFromCoord(latitude: number, longitude: number): void {
    this.locationService.searchAdressByCoordinates(latitude, longitude).pipe(
      switchMap((response: IBuilding) => {
        const userTownWithCoords = { townId: response.town.id, latitude, longitude };
        this.setUserTownFromNavigator(JSON.stringify(userTownWithCoords));
        return this.store.pipe(
          select(selectCurrentCityId),
          take(1),
          tap((cityId: number) => {
            if (response.town.id !== cityId && this.locationStorageService.location) {
              const localCoords: string[] = this.locationStorageService.location.split(',');
              if (!this.hidePopUp.value) {
                this.store.dispatch(SetPopupVisibleChangeLocationAction({ visible: true }));
              }
              this.store.dispatch(SetVariantsLocationInPopupAction({
                variants: [
                  response.town,
                  {
                    hasInnerUnits: null,
                    hintRu: this.locationStorageService.cityName,
                    hintUa: this.locationStorageService.cityName,
                    id: Number(this.locationStorageService.cityId),
                    latitude: +localCoords[0],
                    longitude: +localCoords[1],
                    nameRu: this.locationStorageService.cityName,
                    nameUa: this.locationStorageService.cityName,
                    nameEn: this.locationStorageService.townEn,
                    regionKey: this.locationStorageService.label,
                    regionName: null
                  }
                ]
              }));
            };
          }),
        );
      }),
    ).subscribe();
  }

  public onChangeCity(locale: ICity, fromHint?: boolean): void {
    if (this.platformLocation.pathname.includes('/pharmacies/')) {
      let splitedPathname: string[] = this.platformLocation.pathname.slice(1).split('/');
      splitedPathname[splitedPathname.length - 1] = locale.nameEn;
      this.router.navigate([splitedPathname.join('/')]);
    }

    this.setCustomLocale(
      locale.latitude,
      locale.longitude
    );
    this.setCustomLabel(locale.regionKey);
    this.setCityId(locale.id);
    this.setTownEn(locale.nameEn);
    if (fromHint) {
      this.setCityname(this.local.getLang() === 'ru' ? locale.nameRu : locale.nameUa);
    } else {
      this.setCityname(this.local.getLang() === 'ru' ? locale.hintRu : locale.hintUa);
    }

    this.reRenderingContent(locale);
  }

  public setCustomLocale(latitude: number, longitude: number, centerMap: boolean = true): void {
      this.locationStorageService.location = [latitude, longitude].join();

      if (isPlatformBrowser(this.platformId)) {
        this.cook.setItem('location', [latitude, longitude].join());
      }

      this.store.dispatch(SetCurrentCoordSuccess({ coord: [latitude, longitude] }));
      if (centerMap) {
        this.store.dispatch(SetCenterMapAction({ center: [latitude, longitude] }));
      }

  }

  public setUserTownFromNavigator(userTown: string): void {
    this.locationStorageService.userTown = userTown;
    this.cook.setItem('userTown', userTown);
    this.store.dispatch(SetCurrentUserTownWithCoords({ town: JSON.parse(userTown) }));
  }

  public setCustomLabel(label: string): void {
    this.locationStorageService.label = label;
    this.cook.setItem('label', label);
    this.store.dispatch(SetCurrenCityLabelSuccess({ label }));
  }

  public setCityId(id: number): void {
    this.locationStorageService.cityId = id.toString();
    this.cook.setItem('cityId', id.toString());
    this.store.dispatch(SetCurrentCityId({ id }));
  }

  public setTownEn(townEn: string): void {
    this.locationStorageService.townEn = townEn;
    this.cook.setItem('townEn', townEn);
    this.store.dispatch(SetCurrentTownEnAction({ townEn }));
  }

  public setCityname(city: string): void {
    this.locationStorageService.cityName = city;
    this.cook.setItem('cityName', encodeURIComponent(city));
    this.store.dispatch(SetCurrentCityAction({ city }));
  }

  public getLocation(): void {
    if (this.locationStorageService.location) {
      const coord = this.locationStorageService.location.split(',').map(item => +item);
      this.store.dispatch(SetCurrentCoordSuccess({ coord }));
      this.store.dispatch(SetCenterMapAction({ center: coord }));
    } else if (this.cook.getItem('location')) {
      const coord = this.cook.getItem('location').split(',').map(item => +item);
      this.store.dispatch(SetCurrentCoordSuccess({ coord }));
      this.store.dispatch(SetCenterMapAction({ center: coord }));
    } else {
      this.setDefaultLoc();
    }
  }

  public reRenderingContent(city?: ICity): void {
    this.store.dispatch(ResetOtherPageCountLoadPharmaciesAction());
    let oldQueryParams: Params;
    this.activatedRoute.queryParams.pipe(take(1))
      .subscribe((params: Params) => {
        oldQueryParams = params;
      });
    if (window.location.pathname.includes('pharmacies')) {
      let data: RequestParamsListPharmcy[] = [];

      if (oldQueryParams.id) {
        if (oldQueryParams.p && oldQueryParams.b) {
          data = [{ id: oldQueryParams.id, packageNum: oldQueryParams.p, blisterNum: oldQueryParams.b }];
        } else if (oldQueryParams.p) {
          data = [{ id: oldQueryParams.id, packageNum: oldQueryParams.p, blisterNum: 0 }];
        } else {
          data = oldQueryParams.id.split(',').map((i: any) => ({
            id: i.split('_')[0],
            packageNum: i.split('_')[1], blisterNum: i.split('_')[2]
          }));
        }
      } else {
        const productId: string = this.pharmaciesNavigationHelper.getProductIdFromLocationPathname();
        data = [{ id: +productId, packageNum: 1, blisterNum: 0 }];
      }

      const filterParams: Map<string, FilterListPharmcies> = new Map();
      for (const key in oldQueryParams) {
        if (key === 'fif' || key === 'enf' || key === 'ds' || key === 'ps' || key === 'qs') {
          filterParams.set(key, { name: key, active: oldQueryParams[key] });
        }
      }

      this.store.dispatch(ClearListPharmaciesAction());
      this.store.dispatch(ResetPageCountLoadPharmaciesAction());
      this.store.dispatch(setTotalPharmaciesLengthAction({ total: false }));
      this.store.dispatch(ChangePharmaciesCityAction({ newCity: city }));

      const townIds = [Number(this.locationStorageService.cityId)];

      let userTown: { townId?: number, latitude?: number, longitude?: number } = JSON.parse(this.locationStorageService.userTown || '{}');

      if (userTown.townId) {
        townIds.push(userTown.townId);
      }

      if (city) {
        townIds.push(city.id);
      }

      const isTownIdsEqual = townIds.every((id) => id === townIds[0]);

      if (isTownIdsEqual || this.locationStorageService.address) {
        filterParams.set('ds', { name: 'ds', active: true });
      } else {
        filterParams.delete('ds');
      }

      this.searchPharmaciesApiService.getListPharmcies(data, filterParams, undefined, city);
      this.searchPharmaciesApiService.getListMarkersPharmcies(data, filterParams, city);

    } else if (window.location.pathname.includes('info-drug')) {
      this.infoDrugApiService.getProductById(+window.location.pathname.split('/').reverse()[0])
        .pipe(
          mergeMap((product: IProduct) => {
            return this.infoDrugHelper.intoMergeMapHelper(product);
          }),
          map(([product, analogs, similar, list, favorite]) => {
            return this.infoDrugHelper.intoMapHelper(product, similar, analogs, list, favorite);
          })
        )
        .subscribe((data) => {
          this.infoDrugHelper.setDataToNgRxStore(data);
        });
    } else if (window.location.pathname.includes('category')) {
      this.store.dispatch(ClearFoundActionSuccess());
      this.store.dispatch(SetTargetSuccess({
        target: oldQueryParams.pattern, life: '', option: {
          id: 2,
          name: oldQueryParams.pattern,
          url: window.location.pathname.includes('/page') ? window.location.pathname.split('/').reverse()[1] : window.location.pathname.split('/').reverse()[0],
          info: '3'
        },
        p: true,
        anyParams: {}
      }));

    } else if (window.location.pathname.includes('search')) {
      this.store.dispatch(ClearFoundActionSuccess());
      this.store.dispatch(SetTargetSuccess({
        target: oldQueryParams.pattern, life: '', option: {
          id: oldQueryParams.sectionId,
          name: oldQueryParams.pattern,
          url: window.location.pathname.includes('/page') ? window.location.pathname.split('/').reverse()[1] : window.location.pathname.split('/').reverse()[0],
          info: oldQueryParams.info,
        },
        p: true,
        anyParams: {}
      }));
    } else if (window.location.pathname.includes('/list/')) {
      const urls: string[] = window.location.pathname.slice(1).split('/');
      this.store.dispatch(ClearFoundActionSuccess());
      this.store.dispatch(SetTargetSuccess({
        target: '',
        life: '',
        option: {
          id: 1,
          info: 'trade',
          url: '_',
          tradeNameEn: 'true',
          name: this.local.getLang() === 'ua' ? urls[2] : urls[1],
        },
        p: true,
        anyParams: {}
      }));
    } else if (window.location.pathname.includes('often')) {
      this.store.pipe(
        take(1),
        select(currentMostOftenGroup),
        filter(value => value !== null)
      ).subscribe((group) => this.topBookingApiService.getGroupOfTopReserving(group));

    } else if (window.location.pathname === '/' || window.location.pathname === '/ua') {
      this.topBookingApiService.getTopBookingProducts();
      this.favoriteGoods.getProductsFromFavorite();
      this.viewedGoodsApiService.getProductsFromViewed();
    } else if (window.location.pathname.includes('/catalog/')) {
      const lang: string = this.local.getLang();
      const urls: string[] = window.location.pathname.slice(1).split('/');
      const targetUrl: string = lang === 'ua' ? urls[2] : urls[1];
      this.catalogCityChangeEmitter.emit(targetUrl);
    }

    if (this.screen.isMobile() && !window.location.pathname.includes('/preparat/')) {
      this.store.dispatch(ClearFoundActionSuccess());
    }
  }

  public getCityId(): void {
    if (this.locationStorageService.cityId) {
      this.store.dispatch(SetCurrentCityId({ id: +this.locationStorageService.cityId }));
    } else if (this.cook.getItem('cityId')) {
      this.store.dispatch(SetCurrentCityId({ id: +this.cook.getItem('cityId') }));
    } else {
      this.setDefaultCityId();
    }
  }

  public getCityName(): void {
    if (this.locationStorageService.cityName) {
      this.store.dispatch(SetCurrentCityAction({ city: this.locationStorageService.cityName }));
    } else if (this.cook.getItem('cityName')) {
      this.store.dispatch(SetCurrentCityAction({ city: this.cook.getItem('cityName') }));
    } else {
      this.setDefaultCityname();
    }
  }

  public getCityCurrent(): void {
    if (this.locationStorageService.city) {
      this.store.dispatch(SetCurrentCitySuccess({ city: this.locationStorageService.city }));
    } else if (this.cook.getItem('city')) {
      this.store.dispatch(SetCurrentCitySuccess({ city: this.cook.getItem('city') }));
    }
  }

  public getUserTownFromNavigator(): void {
    if (this.locationStorageService.userTown) {
      this.store.dispatch(SetCurrentUserTownWithCoords({ town: JSON.parse(this.locationStorageService.userTown) }));
    } else if (this.cook.getItem('userTown')) {
      this.store.dispatch(SetCurrentUserTownWithCoords({ town: JSON.parse(this.cook.getItem('userTown')) }));
    } else {
      this.store.dispatch(SetCurrentUserTownWithCoords({ town: { townId: 1240, latitude: 50.4500336, longitude: 30.5241361 } }));
    }
  }

  public getLabelCurrent(): void {
    if (this.locationStorageService.label) {
      this.store.dispatch(SetCurrenCityLabelSuccess({ label: this.locationStorageService.label }));
    } else if (this.cook.getItem('label')) {
      this.store.dispatch(SetCurrenCityLabelSuccess({ label: this.cook.getItem('label') }));
    } else {
      this.setDefaultLabel();
    }

  }

  public setDefaultCityId(): void {
    this.setCityId(1240);
  }

  public setDefaultAddressHistory(): void {
    const addressHistoryState: string = this.locationStorageService.addressHistory;
    if (addressHistoryState === null || addressHistoryState === undefined) {
      this.locationStorageService.addressHistory = '[]';
    }
  }

  public setDefaultAddress(): void {
    const addressState: string = this.locationStorageService.address;
    if (addressState === null || addressState === undefined) {
      this.locationStorageService.address = '';
    }
  }

  public setDefaultCityname(): void {
    if (this.local.getLang() === 'ua') {
      this.setCityname('Київ');
    } else {
      this.setCityname('Киев');
    }
  }

  public setDefaultLoc(): void {
    this.setCustomLocale(50.4500336, 30.5241361);
  }

  public setDefaultLabel(): void {
    this.setCustomLabel('kv');
  }

}
