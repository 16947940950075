import { Injectable } from '@angular/core';
import {
  BzApteka,
  BzProduct,
  FilterListPharmcies,
  ICity,
  PharmacyMarker,
  RequestParamsListPharmcy,
  ResponseBlockPharmacies
} from '@interfaces';
import {
  AddQuantityAction,
  isLoadingListPharmaciesAction,
  LoadListMarkersAction,
  LoadListPharmaciesAction,
  PopupOnePharmcyForActivaMarkerAction,
  SelectedCartMarkerAction,
  SetBannerAction,
  SetSeoFaqsAction,
  SetSeoPreparatPageTextAction,
  SetSeoTableAction,
  setTotalOtherPharmaciesLengthAction,
  setTotalPharmaciesLengthAction
} from '../../../store/actions';
import { QuantityForOneProduct } from '@states';
import {
  selectCountForOneProduct,
  selectCurrentCityId,
  selectCurrentCoord,
  selectIsGeolocationDisabled,
  selectOtherPageCountPharmciesList,
  selectPageCountPharmciesList,
  selectUserTown,
} from '../../../store/selectors';
import { HttpClient, HttpParams } from '@angular/common/http';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { combineLatest, forkJoin, of } from 'rxjs';
import { PharmcyPageService } from '../helpers/pharmcy-page.service';
import { LocalStorageService, LocalizationService, SeoService } from '@core/services';
import { Store } from '@ngrx/store';
import { AppState } from '@appStates';
import { RestUrlService, TypeUrl } from './settings/rest-url.service';
import { LocationStorageService } from '../storage.services/location-storage-service.service';
import { PlatformLocation } from '@angular/common';
import { environment } from '@env';
import { IFullAddress } from 'src/app/shared/interfaces/location/full-adress-response.model';

@Injectable({
  providedIn: 'root'
})
export class SearchPharmaciesApiService {
  private readonly _url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly locationService: LocationStorageService,
    private readonly urlRest: RestUrlService,
    private readonly platform: PlatformLocation,
    private readonly seoService: SeoService,
    private readonly local: LocalizationService
  ) {
    this._url = 'https:' + this.urlRest.getUrl(TypeUrl.rest);
  }

  private static checkIsQuantityEnoughState(request: RequestParamsListPharmcy[], products: BzProduct[]): boolean {
    if (products.some(product => !product.isQuantityEnough)) {
      return true;
    } else {
      return request.length > products.length;
    }
  }

  public getListPharmcies(
    data: RequestParamsListPharmcy[],
    filterParams?: Map<string, FilterListPharmcies>,
    getOtherCityPharmacies?: boolean,
    city?: ICity
  ): void {
    this.store.dispatch(isLoadingListPharmaciesAction({ loading: true }));
    const selectedAddress: IFullAddress = JSON.parse(this.locationService.address || '{}');
    let coords: number[];
    let quantity: QuantityForOneProduct;
    let pages: number[];
    let opages: number[];

    combineLatest([
      this.store.select(selectCountForOneProduct),
      this.store.select(selectPageCountPharmciesList),
      this.store.select(selectOtherPageCountPharmciesList)
    ]).pipe(
      take(1)
    ).subscribe(([count, pageCount, otherPageCount]) => {
      quantity = count;
      pages = [...pageCount];
      opages = [...otherPageCount];
      if (opages[0] === 0 && opages[1] === 0) {
        opages = [1, 1];
      }
    });

    combineLatest([
      this.store.select(selectCurrentCoord),
      this.store.select(selectCurrentCityId).pipe(filter((value) => !!value)),
      this.store.select(selectUserTown).pipe(filter((value) => !!value)),
      this.store.select(selectIsGeolocationDisabled),
      of(selectedAddress)
    ]).pipe(
      take(1)
    ).subscribe(([currentCoords, currentTownId, userTown, isGeolocationDisabled, address]) => {
      /* Если выбранный город не совпадает с городом пользователя полученным по его координатам из navigator'a,
        то устанавливаем координаты центра города для запроса, в противном случае, если города совпадают и геолокация
        у пользователя включена, то устанавливаем координаты пользователя, а если геолокация выключена, то
        устанавливаем координаты центра города, а так же, если у пользователя выбрана улица, то берем координаты
        для запроса из выбранной улицы. Сделано это для более правильной сортировки списка аптек.
      */
      if (currentTownId !== userTown.townId) {
        coords = [...currentCoords];
      } else {
        if (address.latitude && address.longitude) {
          coords = [address.latitude, address.longitude];
        } else if (!isGeolocationDisabled) {
          coords = [userTown.latitude, userTown.longitude];
        } else {
          coords = [...currentCoords];
        }
      }
    });

    let currentTownEn: string = this.locationService.townEn;
    const townFromUrl: string = this.getTownFromUrl();
    if (townFromUrl !== currentTownEn) {
      currentTownEn = townFromUrl;
    }
    if (city) {
      currentTownEn = city.nameEn;
    }

    const request = (ids: Set<number>) => {
      let params: HttpParams = new HttpParams()
        .set('latitude', coords?.[0]?.toString())
        .set('longitude', coords?.[1]?.toString())
        .set('pStart', pages?.[0].toString())
        .set('pEnd', pages?.[1].toString())
        .set('cart', Array.from(ids).filter(e => e).join(','))
        .set('townEn', currentTownEn);

      if (filterParams) {
        filterParams.forEach(item => {
          params = params.append(item.name, String(item.active));
        });
      }

      if (getOtherCityPharmacies) {
        params = params.append('other', 'true')
          .append('oStart', opages[0].toString())
          .append('oEnd', opages[1].toString());
      } else {
        params = params.append('other', 'false');
      }

      if (data) {
        this.http.post<ResponseBlockPharmacies>(this._url + '/api/search-on-pharmacy/block-pharmacy', data, { params })
          .pipe(
            mergeMap((response: ResponseBlockPharmacies) => forkJoin([
              of(response),
              this.localStorageService.getIdsAllGoodsInPharmacies()
            ])),
            map(([blockPharmacies, mapIds]) => {
              if (environment.production && pages[0] === 1) {
                this.handleIndexingForEmptyPharmacyList(blockPharmacies.pharmacyList);
              }

              let pharmaciesInCart: BzApteka[] = [];

              if (!!blockPharmacies.pharmaciesInCart) {
                pharmaciesInCart = blockPharmacies.pharmaciesInCart.map((el: BzApteka) => {
                  return { ...el, toTop: true };
                });
              }

              const pharmacies: BzApteka[] = [...pharmaciesInCart, ...blockPharmacies.pharmacyList];
              const mapPharmcies: Map<number, BzApteka> = new Map();

              pharmacies.forEach((pharmacy: BzApteka) => {

                let res = false;

                if (mapIds.has(pharmacy.id)) {
                  res = PharmcyPageService.allProductInCart(
                    pharmacy.products.map(e => e.id),
                    mapIds.get(pharmacy.id)
                  );
                }

                if (getOtherCityPharmacies) {
                  pharmacy.isOtherCity = true;
                }

                mapPharmcies.set(pharmacy.id, {
                  ...pharmacy,
                  isCart: mapIds.has(pharmacy.id) && mapIds.get(pharmacy.id) ?
                    pharmacy.products.map((product) => product.id)
                      .map((productId) => mapIds.get(pharmacy.id).has(productId))
                        .every((isProductInCart) => isProductInCart) :
                          false,
                  requestOty: (data.length === 1) ? data[0].quantity : null,
                  isCartCurrent: res,
                  isQuantityEnoughList: SearchPharmaciesApiService.checkIsQuantityEnoughState(data, pharmacy.products),
                  searchTotalNum: data.length,
                  products: pharmacy.products.map((product: BzProduct) => {
                    return {
                      ...product,
                      isOrder: mapIds.has(pharmacy.id) ? mapIds.get(pharmacy.id).has(product.id) : false
                    };
                  })
                });
              });

              return { ...blockPharmacies, pharmacyMap: mapPharmcies };
            })
          )
          .subscribe((pharmies: ResponseBlockPharmacies) => {
            if (pharmies.pharmacyMap.size > 0 && [...pharmies.pharmacyMap.entries()][0][1]) {
              quantity = PharmcyPageService
                .getQuantityForOneProduct([...pharmies.pharmacyMap.entries()][0][1]);
            } else {
              quantity = {
                qty: 1,
                packageContentNum: 0,
                balanceNum: 1,
                packageContentType: this.local.getLang() === 'ua' ? 'Блістер' : 'Блистер'
              };
            }

            this.store.dispatch(AddQuantityAction(
              { quantity: { ...quantity, packageNum: +data[0].packageNum, blisterNum: +data[0].blisterNum } }));
            this.store.dispatch(LoadListPharmaciesAction({ pharmacies: pharmies.pharmacyMap }));
            this.store.dispatch(setTotalPharmaciesLengthAction({ total: pharmies.totalPharmacyNumber }));
            this.store.dispatch(setTotalOtherPharmaciesLengthAction({ total: pharmies.totalOtherPharmacies }));
            this.store.dispatch(isLoadingListPharmaciesAction({ loading: false }));
            this.store.dispatch(SetSeoFaqsAction({ pharmacyFaq: pharmies.pharmacyFaq }));
            this.store.dispatch(SetSeoTableAction({ seoPrice: pharmies.seoPrice }));
            this.store.dispatch(SetBannerAction({ banner: pharmies.adBanner }));
            this.store.dispatch(SetSeoPreparatPageTextAction({ seoPreparat: pharmies?.seoPreparat ?? '' }));
          });
      }
    };
    this.localStorageService.getCartPharmaIdsSet()
      .pipe(take(1))
      .subscribe((ids: Set<number>) => request(ids));
  }

  public getListMarkersPharmcies(
    id?: RequestParamsListPharmcy[],
    filters?: Map<string, FilterListPharmcies>,
    city?: ICity
  ): void {
    let coords: number[];
    this.store.select(selectCurrentCoord).subscribe((g: number[]) => {
      coords = [...g];
    }).unsubscribe();

    let currentTownEn: string = this.locationService.townEn;
    const townFromUrl: string = this.getTownFromUrl();
    if (townFromUrl !== currentTownEn) {
      currentTownEn = townFromUrl;
    }
    if (city) {
      currentTownEn = city.nameEn;
    }

    let params: HttpParams = new HttpParams()
      .set('latitude', coords[0].toString() || null)
      .set('longitude', coords[1].toString() || null)
      .set('townEn', currentTownEn);
    if (filters) {
      filters.forEach(item => {
        if (item.active) { params = params.append(item.name, item.active.toString()); }
      });
    }

    this.http.post<PharmacyMarker[]>(this._url + '/api/search-on-pharmacy/map', id, { params })
      .pipe(
        mergeMap(markers => forkJoin([of(markers), this.localStorageService.getIdsAllGoodsInPharmacies()])),
        map(([markers, idsMap]) => {
          return markers.map(marker => {
            return { ...marker, cart: idsMap.has(marker.id) };
          });
        })
      )
      .subscribe((markers: PharmacyMarker[]) => {
        this.store.dispatch(LoadListMarkersAction({ markers }));
      });
  }

  public getOnePharma(id: number, p: RequestParamsListPharmcy[]): void {
    this.store.dispatch(SelectedCartMarkerAction({ id }));
    let geo: number[];
    this.store.select(selectCurrentCoord).subscribe((g: number[]) => {
      geo = [...g];
    }).unsubscribe();

    const params: HttpParams = new HttpParams()
      .set('id', id.toString())
      .set('latitude', geo[0].toString() || null)
      .set('longitude', geo[1].toString() || null);
    this.http.post<BzApteka>(this._url + '/api/search-on-pharmacy/single-pharmacy', p, { params })
      .pipe(
        mergeMap(a => forkJoin([of(a), this.localStorageService.getIdsAllGoodsInPharmacies()])),
        map(([a, idsMap]) => {
          return {
            ...a,
            productTotalNum: a.products.length,
            searchTotalNum: p.length,
            isCart: idsMap.has(a.id) && idsMap.get(a.id) ?
              a.products.map(e => e.id).map(i => idsMap.get(a.id).has(i)).every(tr => tr) : false,
            requestOty: (p.length === 1) ? p[0].quantity : null,
            isQuantityEnoughList: SearchPharmaciesApiService.checkIsQuantityEnoughState(p, a.products),
            isCartCurrent: idsMap.get(a.id) ? a.products.map(e => e.id).map(i => idsMap.get(a.id).has(i)).every(tr => tr) : false,
            products: a.products.map((element: BzProduct) => ({
              ...element,
              isOrder: idsMap.get(a.id) ? idsMap.get(a.id).has(element.id) : false
            }))
          };
        })
      )
      .subscribe((apteka: BzApteka) => {
        this.store.dispatch(PopupOnePharmcyForActivaMarkerAction({ pharmcy: { ...apteka, isOpenInfo: false } }));
        this.store.dispatch(LoadListPharmaciesAction({ pharmacies: new Map().set(apteka.id, apteka) }));
      });
  }

  public getTownFromUrl(): string {
    const splitedPathname: string[] = this.platform.pathname.slice(1).split('/');
    const townFromUrl: string = splitedPathname[splitedPathname.length - 1];
    return townFromUrl;
  }

  private handleIndexingForEmptyPharmacyList(pharmacyList: BzApteka[]): void {
    if (pharmacyList.length === 0) {
      this.seoService.addBlockedForIndexing();
    } else {
      if (environment.production) {
        this.seoService.addCustomBlockedForIndexing('index,follow');
      }
    }
  }

}
